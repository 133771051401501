<template>
    <div class="w-full h-full flex flex-col items-center justify-center p-6">
        <!-- If accounts has responded and user participate on MORE THAN 1 account -->
        <!-- showNewAccount: {{showNewAccount}} -->
        
        <div v-if="loading">
            <div class="fixed inset-0 flex items-center justify-center">
                <div class="spinner bg-green"></div>
            </div>
        </div>
        <div 
            v-else
            class="bg-beige overflow-hidden rounded-lg p-6">
            
            <div 
                v-if="!showNewAccount"
                class="overflow-auto w-100  h-full">
                
                <h2>
                    Upsell konto(er)
                </h2>
                
                <p v-if="accounts.length > 1">
                    Din bruker deltar i flere enn én kontoer. Vær vennlig å velge den kontoen du vil bruke nå: 
                    <!-- auth.chooseOneAccount -->
                </p>
                
                <div class="grid grid-cols gap-4 mt-6">
                    <div 
                        v-for="account in accounts"
                        :key="account.accountId"
                        @click="setAccount(account)"
                        class="p-6 bg-white border border-beige-dark rounded cursor-default hover:bg-beige-dark">
                        
                        {{account.name}}
                    </div>
                </div>
                
            </div>
            
            <div 
                v-if="showNewAccount"
                class="">
                
                <h2>
                    Opprett ny Upsell konto
                </h2>
                
                <form @submit.prevent="create()">
                    <div class="md:grid grid-cols-2 gap-6">
                        <div class="mb-6">
                            <InputSingleline 
                                v-model="accountNew.name"
                                :required="true"
                                label="Bedriftnavn"
                                name="name"
                                :showRequiredWarning="false"
                                class="flex-1"
                                placeholder="Bedriftnavn"
                                ref="autofocus"
                            />
                            
                            <InputSelect 
                                v-model="accountNew.industry"
                                :required="true"
                                label="Bransje"
                                name="industry"
                                class="flex-1 mb-4"
                                :showRequiredWarning="false"
                                :options="industriesArray"
                            />
                            
                            <!-- <InputSingleline 
                                v-model="accountNew.contact.email"
                                label="E-postadresse"
                                name="email"
                                :required="true"
                            /> -->
                            
                            <InputSingleline 
                                v-model="accountNew.contact.phone"
                                label="Telefonnummer"
                                name="phone"
                                :required="false"
                            />
                        </div>
                        <div class="">
                            <InputSingleline 
                                v-model="accountNew.contact.street"
                                label="Gate"
                                name="street"
                                :required="true"
                            />
                            
                            <div class="grid grid-cols-2 gap-6 -my-4">
                                <InputSingleline 
                                    v-model="accountNew.contact.postcode"
                                    label="Postnummer"
                                    name="postcode"
                                    :required="true"
                                />
                                
                                <InputSingleline 
                                    v-model="accountNew.contact.city"
                                    label="By"
                                    name="city"
                                    :required="true"
                                />
                            </div>
                            
                            <InputSingleline 
                                v-model="accountNew.contact.country"
                                label="Land"
                                :name="'country'"
                                class="w-full"
                                :required="true"
                            />
                        </div>
                    </div>
                    
                    <div class="flex items-center space-x-6 justify-end">
                        
                        <button 
                            v-if="accounts && accounts.length != 0"
                            @click="showNewAccount = null">
                            
                            Avbryt
                        </button>
                        <router-link 
                            v-else
                            :to="{ name: 'www' }">
                            
                            Tilbake til websidene
                        </router-link>
                        
                        <button 
                            :disabled="loading"
                            class="button submit"
                            :class="{'loading' : loading == 'creating'}"
                            type="submit">
                            
                            Fortsett
                        </button>
                     </div>
                </form>
            </div>
            <div 
                v-else
                class="mt-6 text-center">
                
                <a 
                    @click="showNewAccount = true"
                    class="link">
                    
                    Opprett ny Upsell-konto
                </a>
            </div>
        </div>
        
        <div 
            v-if="!loading && signedInUser"
            class="flex-none text-center">
            
            <div class="my-6">
                Logget inn som {{ signedInUser.attributes.email }}
            </div>
            
            <div class="flex space-x-4 justify-center items-center">
                <button 
                    @click="signOut()"
                    class="button ">
                    
                    Logg ut
                </button>
                
                <router-link 
                    v-if="isSuperAdmin"
                    :to="{ name: 'superadmin-home' }"
                    class="button submit"
                    style="background: #444;">
                    
                    Enter SuperAdmin
                </router-link>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';

    export default {
        mixins: [
            AuthMixins,
        ],
        
        computed: {
            // getUser(){
            //     return this.$store.getters.getUser;
            // },
            // 
            // getAccount(){
            //     return this.$store.getters.getAccount;
            // },
            
            signedInUser(){
                return this.$store.getters.getUser;
            },
            
            accounts(){
                if (this.accountsRaw) {
                    return this.accountsRaw
                        .filter( a => {
                            if (a.name) {
                                return true;
                            }
                        })
                        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                }
                
                return null;
            },
            
            isSuperAdmin(){
                if ( 
                    this.signedInUser && 
                    this.signedInUser.attributes['custom:superadmin'] == 'true') {
                    
                    return true;
                }
                
                return false;
            },
            
        },
        
        data(){
            return {
                accountsRaw: null,
                loading: true,
                accountNew: {
                    name: null,
                    contact: {
                        webpage: null,
                        email: null,
                        phone: null,
                        street: null,
                        postcode: null,
                        city: null,
                        country: null,
                    },
                    industry: null,
                    promoCode: null,
                },
                showNewAccount: false,
            }
        },
        
        methods: {
            async create(){
                try {
                    if (!this.accountNew.name) {
                        throw Error('Du må skrive et dedriftnavn')
                    }
                    
                    this.loading = 'creating';
                    const account = await this.accountsCreate( this.accountNew );
                    console.log('account response: ', account);
                    
                    this.setAccount( account );
                } 
                catch (e) {
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
            async setAccount( account ){
                console.log('setAccount', account);
                this.loading = 'setting';
                await this.$store.dispatch('setAccount', account);
                
                // check if the user is admin
                
                
                
                // console.log(this.signedInUser.attributes);
                
                
                if (this.signedInUser.username && this.signedInUser.username == account.owner.username) {
                    console.log('this.signedInUser.username == account.owner.username');
                    this.$router.push({ name: 'home', params: {accountId: account.accountId} });
                }
                else if (this.signedInUser.attributes.zoneinfo) {
                    console.log('this.signedInUser.attributes.zoneinfo');
                    const accountId = account.accountId;
                    const departments = JSON.parse( this.signedInUser.attributes.zoneinfo );
                    
                    // console.log('departments', departments);
                    
                    if (departments[accountId] && departments[accountId] == 'admin') {
                        // console.log('admin!');
                        this.$router.push({ name: 'home', params: {accountId: account.accountId} });
                    }
                    else {
                        // console.log('app!');
                        this.$router.push({ name: 'app-departments', params: {accountId: account.accountId} });
                    }
                }
                
                this.loading = null;
            },
        },
        
        async mounted(){
            // console.log('accounts mounted...');
            
            try {
                this.accountsRaw = await this.accountsList();
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: e.message
                });
                
                console.error(e);
                console.log('signout called...');
                this.$router.push({name: 'signout'});
            }
            finally {
                this.loading = null;
            }
            
            // if ( this.isSuperAdmin ) {
            //     console.log('is superadmin!');
            // }
            
            const currentUrl = new URL( window.location.href );
            
            if( currentUrl.searchParams.get('showNewAccount') && currentUrl.searchParams.get('showNewAccount') == 'true' ){
                this.showNewAccount = true; 
                console.log('showNewAccount!');
            }
            else if ( this.accounts && this.accounts.length == 0 ){
                this.showNewAccount = true; 
                console.log('showNewAccount because no accounts!', this.accounts);
            }
            
            else if ( this.accounts && this.accounts.length == 1 ) {
                console.log('found one account. should redirect to this one...');
                this.setAccount( this.accounts[0] );
            }
            
            if (this.signedInUser) {
                this.accountNew.contact.email = this.signedInUser.attributes.email;
            }
        }
    }
</script>